import reportTypes from './report.types';

export const saveToDraftAction = (payload, cb, cbError) => ({
  type: reportTypes.SAVE_REPORT_TO_DRAFT,
  payload,
  cb,
  cbError
});

export const fetchPatientResultAction = (payload, cb, cbError) => ({
  type: reportTypes.GET_PATIENT_RESULT,
  payload,
  cb,
  cbError
});

export const fetchAnotherBranchPatientRecordAction = (payload, cb, cbError) => ({
  type: reportTypes.GET_INCOMING_PATIENT_RECORD,
  payload,
  cb,
  cbError
});

export const fetchReportingEligibilityStatusAction = (payload, cb, cbError) => ({
  type: reportTypes.GET_REPORTING_ELIGIBILITY_STATUS,
  payload,
  cb,
  cbError
});
