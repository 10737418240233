const reportTypes = {
  SAVE_REPORT_TO_DRAFT: 'SAVE_REPORT_TO_DRAFT',
  GET_PATIENT_RESULT: 'GET_PATIENT_RESULT',
  SET_PATIENT_RESULT: 'SET_PATIENT_RESULT',
  GET_INCOMING_PATIENT_RECORD: 'GET_INCOMING_PATIENT_RECORD',
  SET_INCOMING_PATIENT_RECORD: 'SET_INCOMING_PATIENT_RECORD',
  GET_REPORTING_ELIGIBILITY_STATUS: 'GET_REPORTING_ELIGIBILITY_STATUS'
};

export default reportTypes;
